<template>
	<input
		ref="input"
		v-model="value"
		class="form__input">
</template>

<script setup>
import { onMounted, ref } from 'vue';

const value = defineModel();
const input = ref(null);

onMounted(() => {
	if (input.value.hasAttribute('autofocus')) {
		input.value.focus();
	}
});

defineExpose({ focus: () => input.value.focus() });
</script>
